var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
 var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

function degToCompass(num) {
  var val = Math.floor((num / 22.5) + 0.5);
  var arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
  return arr[(val % 16)];
}

function renderTime(unix_timestamp,format){
  var a = new Date(unix_timestamp);
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() < 10 ? '0' + a.getDate() : a.getDate();
  var day = days[a.getDay()];
  var hour = a.getHours() < 10 ? '0' + a.getHours() : a.getHours();
  var min = a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
  if(format == 'day'){
    return day + ' ' + date + '.' + (a.getMonth() + 1) + '.' + a.getFullYear().toString().substr(-2);
  }
  if(format == 'time'){
    return hour + ':' + min;
  }
  if(format == 'tide'){
    var tmpmo = a.getMonth() + 1;
    var mo = tmpmo < 10 ? '0' + tmpmo : tmpmo;

    return year + '-' + mo + '-' + date;
  }
  return time;
}


const lessThanOneHourAgo = (date) => {
  const HOUR = 1000 * 60 * 1;
  const anHourAgo = Date.now() - HOUR;
  return date > anHourAgo;
}